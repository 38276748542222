/**
 * layout上下文
 */
export interface LayoutContext {
  /** 自适应屏幕尺寸，在游戏详情需要 */
  needResponsive: Ref<boolean>
  /** 是否铺满屏幕，高度小于725的屏幕需要 */
  fillView: Ref<boolean>
}

// eslint-disable-next-line symbol-description
const injectionKey: InjectionKey<LayoutContext> = Symbol()

export function createLayoutProvide(state: LayoutContext) {
  provide(injectionKey, shallowReactive(state))
}

export function useLayoutInject(): LayoutContext {
  return inject(injectionKey)!
}

/**
 * nav上下文
 */
export interface LayoutNavContext {
  searchActive: Ref<boolean>
  personalActive: Ref<boolean>
  historyActive: Ref<boolean>
  /** 固定顶部 */
  fixedTop: Ref<boolean>
}

// eslint-disable-next-line symbol-description
const layoutNavKey: InjectionKey<LayoutNavContext> = Symbol()

export function createLayoutNavProvide(state: LayoutNavContext) {
  provide(layoutNavKey, shallowReactive(state))
}

export function useLayoutNavInject(): LayoutNavContext {
  return inject(layoutNavKey)!
}
