<script setup lang="ts">
import { useLayoutInject } from './context'

defineOptions({ inheritAttrs: false })
const { needResponsive } = useLayoutInject()

const visible = defineModel<boolean>({ default: false })
</script>

<template>
  <Teleport to="body">
    <!-- popover component -->
    <Transition name="popover-visible">
      <div v-if="visible" aria-modal="true" class="fixed inset-0 z-30 of-y-auto overscroll-none [&::-webkit-scrollbar]:size-0px">
        <!-- The style `overscroll-none of-y-scroll` and `h="[calc(100%+0.5px)]"` is used to implement scroll locking, -->
        <!-- mask layer -->
        <div
          class="popover-mask absolute inset-0 z-0 h-[calc(100%+0.5px)] touch-none bg-transparent"
          lt-lg:landscape="bg-#000 bg-op-50" lt-md:portrait="bg-#000 bg-op-50"
          @click="visible = false"
        />
        <!-- popover container -->
        <div class="pointer-events-none absolute inset-0 z-1 flex op-100">
          <div
            class="flex flex-1 items-start justify-end" lt-lg="mt-14" lg="mt-17"
            lt-lg:landscape="mt-12 items-end" lt-md:portrait="mt-12 items-end"
            :class="needResponsive ? 'filled-content' : 'md:max-w-300 md:mx-auto'"
          >
            <div
              class="popover-main pointer-events-auto isolate max-h-full touch-pan-x touch-pan-y of-y-auto overscroll-contain"
              lt-lg:landscape="rd-t-3" lt-md:portrait="rd-t-3"
            >
              <slot />
            </div>
          </div>
        </div>
      </div>
    </Transition>
  </Teleport>
</template>

<style lang="scss" scoped>
.popover-visible-enter-active,
.popover-visible-leave-active {
  transition-duration: 0.2s;

  .popover-main {
    transition:
      opacity 0.2s ease,
      transform 0.15s ease;
  }
}

.popover-visible-enter-from,
.popover-visible-leave-to {
  .popover-main {
    transform: translateY(-10px);
    opacity: 0;
  }
}

@media (max-width: 1023.9px) and (orientation: landscape) {
  .popover-visible-enter-from,
  .popover-visible-leave-to {
    .popover-main {
      transform: translateY(3rem);
      opacity: 0;
    }
  }
}

@media (max-width: 767.9px) and (orientation: portrait) {
  .popover-visible-enter-from,
  .popover-visible-leave-to {
    .popover-main {
      transform: translateY(3rem);
      opacity: 0;
    }
  }
}
</style>
