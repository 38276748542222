<script setup lang="ts">
import { useLayoutNavInject } from './context'
import SVG_SEARCH_ICON from '~/assets/svg/search-icon.svg'
import SVG_CLOSE_ICON from '~/assets/svg/close-btn.svg'

const isSearchActive = defineModel<boolean>({ required: true })

const { fixedTop, historyActive, personalActive } = useLayoutNavInject()

const { keyword: searchText, searchResultData } = useSearch(5)
const searchRef = ref<HTMLDivElement>()
const inputRef = ref<HTMLInputElement>()

async function focusSearch() {
  isSearchActive.value = true
  await nextTick()
  inputRef.value?.focus()
}

async function clearSearch() {
  searchText.value = ''
  inputRef.value?.blur()
  await nextTick()
  isSearchActive.value = false
}

onClickOutside(inputRef, clearSearch)
</script>

<template>
  <div
    ref="searchRef"
    class="relative flex items-center rd-2 px-3 transition-all-300" v-bind="$attrs" bg="gray-2 hover:gray-3 focus:gray-3"
    :class="[
      isSearchActive ? 'w-70vw md:w-81' : 'w-9 md:w-50',
      (fixedTop || isSearchActive || historyActive || personalActive) && 'bg-gray-3!',
    ]"
    @click="focusSearch"
  >
    <SVG_SEARCH_ICON class="size-4 flex-shrink-0 op-50 md:op-30" :class="!isSearchActive && 'lt-md:mis--0.75 lt-md:size-4.5'" />

    <input
      ref="inputRef"
      v-model="searchText"
      class="h-9 w-full flex-1 truncate bg-transparent text-3.5 c-#fff c-op-90 caret-green-5 transition-150 placeholder-#fff placeholder-op-30"
      lt-md="mx-2" md="mis-3 mie-6"
      type="text" :placeholder="$t('nav.searchPlaceholder')"
      autocomplete="off"
      @keydown.enter="navigateTo({ path: '/search.html', query: { s: searchText } })"
    >

    <SVG_CLOSE_ICON
      v-if="searchText.length" class="absolute top-50% cursor-pointer op-30 transition-150 -translate-y-50%"
      lt-md="h-full w-[calc(30vw-3.5rem)] op-0 -inset-ie-[calc(30vw-2.5rem)]"
      md="size-3 inset-ie-3"
      @click.stop="clearSearch"
    />
  </div>

  <!-- 纯摆设 -->
  <div
    class="flex-center of-hidden text-3.5 c-#fff c-op-90 font-600 transition-150 md:hidden"
    :class="isSearchActive ? 'w-[calc(30vw-3.5rem)] mis-4 op-100' : 'w-0 op-0 pointer-events-none'"
    @click.stop="clearSearch"
  >
    {{ $t('nav.cancel') }}
  </div>

  <Teleport to="body">
    <LayoutSearchPanel :is-search-active="isSearchActive" :target="searchRef" :search-result-data="searchResultData" />
  </Teleport>
</template>

<style lang="scss" scoped>
</style>
