<script setup lang="ts">
import { useLayoutInject } from './context'
import { locales } from '@/contants/locales'
import SVG_LOGO from '~/assets/svg/logo.svg'
import SVG_DISCORD_ICON from '~/assets/svg/discord.svg'
import SVG_EARTH_ICON from '~/assets/svg/earth-icon.svg'
import SVG_ARROW_BOTTOM_ICON from '~/assets/svg/arrow-bottom-icon.svg'
import SVG_TO_TOP_BTN_ICON from '~/assets/svg/to-top-btn-icon.svg'

const { needResponsive } = useLayoutInject()

const { setLocale, locale } = useI18n()
const isShowChooseLangPanel = ref(false)
const discordUrl = import.meta.env.VITE_DISCORD_URL
const cookieLang = useCookie(import.meta.env.VITE_LANG_COOKIE_KEY, { maxAge: Number(import.meta.env.VITE_LANG_COOKIE_MAX_AGE) })
const localStorageLocale = useLocalStorage(import.meta.env.VITE_LANG_LOCAL_STORAGE_KEY, '')

function scrollToTop() {
  window.scrollTo({ top: 0, behavior: 'smooth' })
}

const langList = ref(locales)
const selectedLangName = computed(() => langList.value.find(lang => lang.code === locale.value)?.name || '')

function selectedLang(name: string) {
  const code = langList.value.find(lang => lang.name === name)?.code
  if (!code)
    return
  setLocale(code)
  cookieLang.value = code
  localStorageLocale.value = code
}

// LINE_Seed_Sans Bebas_Neue OSP-DIN 配置在nuxt.config.ts
// 不同语言配置不同字体文件
const { public: { NUXT_ROOT_CDN_URL } } = useRuntimeConfig()
const fontHrefMap: Record<string, string> = {
  ar_IL: 'fonts/Noto_Sans_Arabic/index.css',
  ja_JP: 'fonts/LINE_Seed_Sans_JP/index.css',
  ko_KR: 'fonts/LINE_Seed_Sans_KR/index.css',
  zh_TW: 'fonts/Noto_Sans_TC/index.css',
  zh_CN: 'fonts/Noto_Sans_SC/index.css',
  th_TH: 'fonts/LINE_Seed_Sans_TH/index.css',
}

useHead(computed(() => ({
  htmlAttrs: {
    dir: locale.value === 'ar_IL' ? 'rtl' : 'ltr',
    lang: langList.value.find(lang => lang.code === locale.value)?.androidLang,
  },
  link: fontHrefMap[locale.value] ? [{ rel: 'stylesheet', href: `${NUXT_ROOT_CDN_URL}${fontHrefMap[locale.value]}` }] : [],
})))
</script>

<template>
  <footer
    class="view-content flex flex-col bg-gray-1" lt-md="mt-10" md="mt-30"
    :class="needResponsive && 'filled-content'"
  >
    <ClientOnly>
      <div class="to-top-btn w-full flex justify-end pb-5.5" b="0 lt-md:b b-2 solid gray-2">
        <button class="size-11 flex-center cursor-pointer rd-2 bg-gray-2 transition-300 hover:bg-gray-3" aria-label="scroll to top" @click="scrollToTop">
          <SVG_TO_TOP_BTN_ICON />
        </button>
      </div>
    </ClientOnly>
    <div class="flex p-0" lt-md="flex-col py-10" md="h-42.5 items-center justify-between">
      <section class="flex" lt-md="flex-col">
        <NuxtLink rel="nofollow" to="/" class="h-10 w-50 -translate-y-1" aria-label="home page">
          <SVG_LOGO width="200" height="40" />
        </NuxtLink>
        <div class="text-3.5 c-#fff c-op-90 lh-3" lt-md="mbs-10" md="mis-10">
          <div class="flex gap-3" md="flex-col gap-2">
            <NuxtLink rel="nofollow" class="w-fit py-0.5 transition-300 hover:c-green-5" to="/abouts.html">
              {{ $t('footer.aboutUs') }}
            </NuxtLink>
            <NuxtLink rel="nofollow" to="/faq.html" class="w-fit py-0.5 transition-300 hover:c-green-5">
              {{ $t('footer.FAQ') }}
            </NuxtLink>
          </div>
          <p lt-md="mbs-3" md="mbs-10">
            <NuxtLink rel="nofollow" to="/privacy-policy.html" class="mie-6 w-fit py-0.5 c-#fff c-op-30 font-400 transition-300 hover:c-green-5">
              {{ $t('footer.policyPrivacy') }}
            </NuxtLink>
            <NuxtLink rel="nofollow" to="/terms-of-use.html" class="mie-6 w-fit py-0.5 c-#fff c-op-30 font-400 transition-300 hover:c-green-5">
              {{ $t('footer.policyTerms') }}
            </NuxtLink>
            <NuxtLink rel="nofollow" to="/cookie-policy.html" class="mie-6 w-fit py-0.5 c-#fff c-op-30 font-400 transition-300 hover:c-green-5">
              {{ $t('footer.policyCookie') }}
            </NuxtLink>
          </p>
        </div>
      </section>
      <section class="flex select-none" lt-md="gap-3 mbs-10" md="gap-6">
        <NuxtLink class="ld-button box-border size-11 transition-300 ld-button-black" :to="discordUrl" target="_blank" external aria-label="discord">
          <SVG_DISCORD_ICON />
        </NuxtLink>
        <div class="group lang relative cursor-pointer" @mouseenter="isShowChooseLangPanel = true" @mouseleave="isShowChooseLangPanel = false">
          <div class="ld-button h-11 min-w-34 transition-300 ld-button-black lt-md:px-4">
            <SVG_EARTH_ICON class="mie-2 size-5" />
            <span class="text-3.5 c-#fff c-op-90 font-600">{{ selectedLangName }}</span>
            <SVG_ARROW_BOTTOM_ICON class="mis-3 transition-300" :class="isShowChooseLangPanel ? 'rotate-180' : ''" />
          </div>

          <ClientOnly>
            <Tooltip class="inset-is-0 top-0 translate-y--100%">
              <OptionsList :options="langList.map(v => v.name)" :selected="selectedLangName" bg-color="#1a1a1a" hover-class="hover-style-2" width="136px" @tap-option="selectedLang" />
              <div class="h-3 w-full" />
            </Tooltip>
          </ClientOnly>
        </div>
      </section>
    </div>
  </footer>
</template>

<style lang="scss" scoped>
</style>
