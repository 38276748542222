<script setup lang="ts">
import { API_GET_ALL_TAGS } from '~/api'
import type { ITagBlockProps } from '~/types/components'

const { globalContext } = useGlobalContext()
const route = useRoute()
const routeBaseName = useRouteBaseName()

const visible = ref(false)
const showText = ref(true)
const tagDrawerRef = ref<HTMLElement>()

const headAnchorRef = ref<HTMLElement>()
// const headAnchorVisible = false
const headAnchorVisible = useElementVisibility(headAnchorRef)
const tailAnchorRef = ref<HTMLElement>()
// const tailAnchorVisible = false
const tailAnchorVisible = useElementVisibility(tailAnchorRef)

const { value, addToCache } = await useDataCache<ITagBlockProps[]>(`api:all_tags`)
const { data } = await useAsyncData('all-tags', async () => {
  if (value)
    return value
  const response = await API_GET_ALL_TAGS()
  await addToCache(response, ['api-data'], 900)
  return response
})

onClickOutside(tagDrawerRef, () => changeVisible(false))

const windowLocked = useScrollLock(import.meta.client ? window : null)
// 解决移动端会让背景也滚动
watch(visible, v => windowLocked.value = (v && showText.value))

const timeOuts = ref<NodeJS.Timeout[]>([])
function changeVisible(v: boolean) {
  if (v && !showText.value)
    showText.value = true

  visible.value = v
  timeOuts.value.forEach(clearTimeout)
  timeOuts.value = []
}

watchOnce(() => routeBaseName(route), async () => {
  showText.value = false
  await nextTick()
  visible.value = true
  const timeout1 = setTimeout(async () => {
    visible.value = false
    const timeout2 = setTimeout(() => {
      showText.value = true
    }, 300)
    timeOuts.value.push(timeout2)
  }, 3000)
  timeOuts.value.push(timeout1)
}, { immediate: true })
</script>

<template>
  <div
    ref="tagDrawerRef"
    class="tag-drawer pointer-events-none fixed z-28 flex items-center c-#fff transition-transform-300"
    lt-md="inset-be-0 inset-is-0 h-fit w-100vw" md="inset-0 h-100vh w-fit"
    :class="[(globalContext.isFullscreen || globalContext.isFullscreen) && 'hidden op-0', !visible && 'lt-md:translate-y-[calc(100%-20px)] md:translate-x-[calc(-100%+20px)] md:rtl:translate-x-[calc(100%-20px)]']"
  >
    <div class="group tag-drawer-box pointer-events-auto z-1 flex flex-col items-center transition-all-300 md:flex-row-reverse">
      <span class="tag-drawer-pin group/item cursor-pointer text-20px lt-md:h-1em flip-rtl" @click="changeVisible(!visible)">
        <svg class="aspect-ratio-20/100 w-1em translate-x--1px lt-md:hidden" width="20" height="100" viewBox="0 0 20 100" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path class="fill-gray-3" d="M0 8.58359C0 13.1289 2.56803 17.284 6.63344 19.3167L13.3666 22.6833C17.432 24.716 20 28.8711 20 33.4164V66.5836C20 71.1289 17.432 75.284 13.3666 77.3167L6.63344 80.6833C2.56804 82.716 0 86.8711 0 91.4164V100V0V8.58359Z" />
          <path class="origin-cc stroke-#fff stroke-op-30 transition-300 group-hover/item:stroke-op-90" :class="visible && 'rotate-180'" d="M9 54L11 50L9 46" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        </svg>

        <svg class="aspect-ratio-101/20 h-1em translate-y-2px md:hidden" viewBox="0 0 101 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path class="fill-gray-3" d="M9.08359 20C13.6289 20 17.784 17.432 19.8167 13.3666L23.1833 6.63344C25.216 2.56803 29.3711 -1.262e-06 33.9164 -1.46068e-06L67.0836 -2.91046e-06C71.6289 -3.10914e-06 75.784 2.56802 77.8167 6.63343L81.1833 13.3666C83.216 17.432 87.3711 20 91.9164 20L100.5 20L0.5 20L9.08359 20Z" />
          <path class="origin-cc fill-#fff fill-op-30 transition-300 group-hover/item:fill-op-90" :class="visible && 'rotate-180'" fill-rule="evenodd" clip-rule="evenodd" d="M57.6858 11.3953C57.4674 12.0502 56.7595 12.4042 56.1046 12.1859L50.4999 10.3176L44.8952 12.1859C44.2403 12.4042 43.5324 12.0502 43.3141 11.3953C43.0957 10.7404 43.4497 10.0325 44.1046 9.81414L50.1046 7.81414C50.3612 7.72861 50.6386 7.72861 50.8952 7.81414L56.8952 9.81414C57.5501 10.0325 57.9041 10.7404 57.6858 11.3953Z" />
        </svg>
      </span>

      <div class="tag-drawer-context group/box relative rd-4 bg-gray-3 p-5 shadow-[0_0_80_0_#0008] lt-md:rd-b-0 lt-md:pb-0">
        <div
          class="tag-drawer-head-mask pointer-events-none absolute from-gray-3 to-transparent transition-all-100"
          md="h-10 w-full top-4 left-0 bg-gradient-to-b"
          :class="[headAnchorVisible && 'op-0', showText ? 'lt-md:h-10 lt-md:w-full lt-md:top-5 lt-md:left-0 lt-md:bg-gradient-to-b' : 'lt-md:w-10 lt-md:h-full lt-md:top-0 lt-md:left-4 lt-md:bg-gradient-to-r']"
        />

        <div class="of-overlay" lt-md="w-[calc(100vw-2.5rem)] max-h-60vh touch-pan-y" md="max-h-[calc(100vh-12rem)] min-h-20 touch-pan-y" :class="!showText && 'flex touch-pan-x'">
          <i ref="headAnchorRef" class="scroll-head-anchor" />

          <div class="grid gap-4 lt-md:grid-cols-[repeat(999,1fr)] lt-md:pb-5 md:grid-cols-1" :class="showText && 'lt-md:grid-cols-2'">
            <CommonTagBlock v-for="i in (data || [])" :key="i.id" :show-text="showText" class="w-auto justify-start b-gray-4 bg-gray-4 [&_.tag-img]:size-6 hover:b-gray-5 hover:bg-gray-5" :class="!showText ? 'p-2!' : 'py-2!'" v-bind="i" />

            <CommonTagBlock class="w-auto justify-start b-gray-4 bg-gray-4 [&_.tag-img]:size-6 hover:b-gray-5 hover:bg-gray-5" :class="!showText ? 'p-2!' : 'py-2!'" :show-text="showText" category-name="All" to="/tags.html" icon="https://res.ldrescdn.com/rms/ldplayer/process/img/40bd04098cae4974aedd7bd29e3676cf1719483273.webp" />
          </div>

          <i ref="tailAnchorRef" class="scroll-tail-anchor" />
        </div>

        <div
          class="tag-drawer-tail-mask pointer-events-none absolute from-gray-3 to-transparent transition-all-100"
          md="h-10 w-full bottom-4 left-0 bg-gradient-to-t"
          :class="[tailAnchorVisible && 'op-0', showText ? 'lt-md:h-10 lt-md:w-full lt-md:bottom-0 lt-md:left-0 lt-md:bg-gradient-to-t' : 'lt-md:w-10 lt-md:h-full lt-md:top-0 lt-md:right-4 lt-md:bg-gradient-to-l']"
        />
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
</style>
