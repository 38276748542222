<script setup lang="ts">
import SVG_SUCCESS_ICON from '~/assets/svg/toast/success.svg'
import SVG_INFO_ICON from '~/assets/svg/toast/info.svg'
import SVG_ERROR_ICON from '~/assets/svg/toast/error.svg'

import { EToastType } from '~/types/components'

const { toastList } = useToast()
</script>

<template>
  <section v-if="toastList.length">
    <div
      v-for="(v, i) of toastList" :key="v.key"
      class="toast-animation fixed inset-is-0 inset-is-50% top-0 z-200 min-w-30.5 w-max flex-center gap-2 rd-2 bg-gray-4 p-4 lt-md:max-w-80vw -translate-x-50%"
      :style="{ zIndex: `${100 + i}` }"
    >
      <SVG_SUCCESS_ICON v-if="v.type === EToastType.SUCCESS" class="flex-shrink-0" />
      <SVG_INFO_ICON v-if="v.type === EToastType.INFO" class="flex-shrink-0" />
      <SVG_ERROR_ICON v-if="v.type === EToastType.ERROR" class="flex-shrink-0" />
      <p class="text-3.5 c-#fff c-op-90 font-600 lh-5">
        {{ v.text }}
      </p>
    </div>
  </section>
</template>

<style lang="scss" scoped>
.toast-animation {
  animation: toast-in-out 3500ms ease-in-out forwards;
  @keyframes toast-in-out {
    0% {
      top: -44px;
      opacity: 0;
    }

    10% {
      top: 68px;
      opacity: 1;
    }

    80% {
      top: 68px;
      opacity: 1;
    }

    100% {
      opacity: 0;
      top: 68px;
      display: none;
    }
  }
}
</style>
