<script setup lang="ts">
import { createLayoutProvide } from '~/components/layout/context'

const route = useRoute()

const RESPONSIVE_PAGES = ['cloud-game', 'html5-games']

const needResponsive = ref(false)
const fillView = ref(false)
const isMobile = ref(mobileCheck())
function resizeChange() {
  needResponsive.value = RESPONSIVE_PAGES.includes(route.name as string)
  isMobile.value = mobileCheck()
  fillView.value = ((window?.innerHeight || Number.POSITIVE_INFINITY) <= 725) && !isMobile.value
}

watch(() => route.name, resizeChange)
onMounted(() => {
  resizeChange()
  window.onresize = resizeChange
})

createLayoutProvide({
  needResponsive,
  fillView,
})
</script>

<template>
  <LayoutNav />

  <Announcement :need-top-zero="needResponsive && fillView" />

  <main>
    <slot />
  </main>

  <LayoutFooter />

  <LayoutTagDrawer />

  <LazyClientOnly>
    <!-- <LayoutPersonal /> -->
    <LayoutToastList />
    <LayoutDesktopToast v-if="needResponsive" :is-mobile="isMobile" />
  </LazyClientOnly>
</template>
