<script setup lang="ts">
import { EGameType, type IHistoryGameProps } from '~/types/components'
import SVG_BANNER_RIGHT_TRIANGLE from '~/assets/svg/banner-right-triangle.svg'
import SVG_HISTORY_CLOCK_BLOD from '~/assets/svg/history-clock-blod.svg'
import SVG_HISTORY_PLAYED from '~/assets/svg/history-played.svg'

interface Props {
  game: IHistoryGameProps
}

const props = defineProps<Props>()

const playMin = computed(() => {
  let timeStr = ''
  const [h, m] = Number(props.game.totalMinutes / 60).toFixed(1).split('.')
  if (+h)
    timeStr += `${h}h`

  if (+m)
    timeStr += `${m}m`

  return timeStr
})

const isAutoStart = ref(false)
const gameDetailUrl = computed(() => `/${props.game.gameType === EGameType.WEBSITE ? 'html5-games' : 'games'}/${props.game.seo}.html${isAutoStart.value ? '?start=1' : ''}`)
</script>

<template>
  <NuxtLink :to="gameDetailUrl" class="flex items-center gap-4 rd-3 bg-gray-4" p="4 md:6">
    <img v-lazy="game.icon" class="rd-2" size="13 md:15" alt="">

    <div class="flex-1">
      <p class="line-clamp-1 text-4 c-#fff c-op-90 font-700">
        {{ game.gameName }}
      </p>
      <p v-show="game.latestStartTime" class="mt-2 text-3 c-#fff c-op-50">
        <SVG_HISTORY_CLOCK_BLOD class="mie-0.5 size-3 v-bottom" />
        {{ game.latestStartTime }}
      </p>
      <p v-show="!!game.totalMinutes" class="mt-1 text-3 c-#fff c-op-50">
        <SVG_HISTORY_PLAYED class="mie-0.5 size-3 v-bottom" />
        {{ playMin }}
      </p>
    </div>

    <button class="ld-button h-10 flex-shrink-0 px-3 ld-button-green" @mouseenter="isAutoStart = true" @mouseleave="isAutoStart = false">
      <SVG_BANNER_RIGHT_TRIANGLE class="size-1.25em" />
      <span>{{ $t('common.play') }}</span>
    </button>
  </NuxtLink>
</template>

<style lang="scss" scoped>
</style>
