<script setup lang="ts">
interface Props {
  direction?: 'portrait' | 'landscape'
  containClass?: any
}

defineProps<Props>()

const headAnchorRef = ref<HTMLElement>()
// const headAnchorVisible = false
const headAnchorVisible = useElementVisibility(headAnchorRef)
const tailAnchorRef = ref<HTMLElement>()
// const tailAnchorVisible = false
const tailAnchorVisible = useElementVisibility(tailAnchorRef)
</script>

<template>
  <div class="scroll-list relative" :class="[direction]">
    <div
      class="scroll-head-mask pointer-events-none absolute from-gray-3 to-transparent transition-all-100"
      :class="[
        headAnchorVisible && 'op-0',
        direction === 'portrait' && 'h-10 w-full top-0 left-0 bg-gradient-to-b',
        direction === 'landscape' && 'w-10 h-full top-0 left-4 bg-gradient-to-r',
      ]"
    />
    <div
      class="scroll-contain of-auto" :class="containClass"
    >
      <i ref="headAnchorRef" />
      <slot />
      <i ref="tailAnchorRef" />
    </div>

    <div
      class="scroll-tail-mask pointer-events-none absolute from-gray-3 to-transparent transition-all-100"
      :class="[
        tailAnchorVisible && 'op-0',
        direction === 'portrait' && 'h-10 w-full bottom-0 left-0 bg-gradient-to-t',
        direction === 'landscape' && 'w-10 h-full top-0 right-4 bg-gradient-to-l',
      ]"
    />
  </div>
</template>

<style lang="scss" scoped>
</style>
