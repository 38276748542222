<script setup lang="ts">
import SVG_LOGO_INVERSE from '~/assets/svg/logo-inverse.svg'
import SVG_CLOSE_ICON from '~/assets/svg/close-btn.svg'
import SVG_DESKTOP from '@/assets/svg/control-bar/desktop.svg'

interface Props {
  isMobile: boolean
}

defineProps<Props>()

const route = useRoute()

/** 停留时长超过10分钟 */
// const WAIT_TIME = 1000 * 3
const WAIT_TIME = 1000 * 60 * 10
const visible = ref(false)
const timeout = ref<NodeJS.Timeout>()

function resetConfig() {
  timeout.value && clearTimeout(timeout.value)
  visible.value = false
}

async function clickInstall() {
  const { $pwa } = useNuxtApp()
  await $pwa?.install()
  window?.dataLayer?.push({ event: 'desktop-toast-download', seo: route.params.name })
}

function clickCancel() {
  const { $pwa } = useNuxtApp()
  $pwa?.cancelInstall()
  resetConfig()
}

watch(() => route.params.name, () => {
  resetConfig()

  timeout.value = setTimeout(() => {
    visible.value = true
  }, WAIT_TIME)
}, { immediate: true })

const el = ref()
const targetIsVisible = useElementVisibility(el)
watchOnce(targetIsVisible, () => {
  window?.dataLayer?.push({ event: 'desktop-toast-exposure', seo: route.params.name })
})

onBeforeUnmount(resetConfig)
</script>

<template>
  <Teleport to="body">
    <Transition name="dialog-visible">
      <div v-if="!isMobile && !$pwa?.isPWAInstalled && visible && $pwa?.showInstallPrompt" ref="el" class="fixed z-3" inset="ie-10 be-10">
        <div class="dialog-main relative rd-4 bg-gray-4 p-10">
          <SVG_CLOSE_ICON class="absolute inset-ie-4 top-4 size-3 cursor-pointer op-30 transition-300" hover="op-90" @click="clickCancel" />

          <div class="absolute left-50% translate-x--50% -top-10">
            <SVG_LOGO_INVERSE class="size-20" />
          </div>

          <div>
            <p class="mt-6 text-center text-4 c-#fff c-op-90 font-700">
              {{ $t('gameDetail.addToDesktop') }}
            </p>

            <div class="mt-10 flex gap-6">
              <button class="ld-button h-11 min-w-37 px-4" bg="op-5 #fff hover:op-10" c="#fff op-90" @click="clickCancel">
                {{ $t('desktop.later') }}
              </button>
              <button class="ld-button h-11 min-w-37 px-4 ld-button-green" @click="clickInstall">
                <SVG_DESKTOP class="mie-2 size-4" viewBox="0 0 20 20" />
                {{ $t('desktop.add') }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </Transition>
  </Teleport>
</template>

<style lang="scss" scoped>
.dialog-visible-enter-active,
.dialog-visible-leave-active {
  transition-duration: 0.25s;

  .dialog-main {
    transition:
      opacity 0.25s ease,
      transform 0.25s ease;
  }
}

.dialog-visible-enter-from,
.dialog-visible-leave-to {
  .dialog-main {
    transform: translateY(50px);
    opacity: 0;
  }
}
</style>
