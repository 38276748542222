<script setup lang="ts">
import { useLayoutNavInject } from './context'
import { API_USER_HISTORY_GAMES } from '~/api'
import type { IHistoryGameProps } from '~/types/components'
import SVG_HISTORY_CLOCK from '~/assets/svg/history-clock.svg'

const { fixedTop, searchActive, historyActive } = useLayoutNavInject()
const { isLogged, userInfo } = useUserInfo()

const { playedList } = usePlayed()
const historyGames = ref<IHistoryGameProps[]>([])

const el = ref<HTMLElement>()
async function tapHistory() {
  historyActive.value = !historyActive.value

  if (isLogged.value) {
    const { shortToken, uid } = userInfo.value
    historyGames.value = await API_USER_HISTORY_GAMES({ token: shortToken, uid })
  }
  else {
    historyGames.value = playedList.value.map(game => ({ ...game, latestStartTime: '', totalMinutes: 0, uid: '' }))
  }
}
</script>

<template>
  <button
    ref="el" v-bind="$attrs"
    mis="3 lg:6" class="ld-button size-9 b-none transition-300 ld-button-black"
    :class="[fixedTop && 'bg-gray-3', historyActive && 'bg-gray-4', searchActive && 'lt-lg:w-0 lt-lg:m-0! lt-lg:of-hidden']" aria-label="history" @click="tapHistory"
  >
    <SVG_HISTORY_CLOCK class="size-6" :c="historyActive ? 'green-5 op-100' : '#fff op-50 hover:green-5 hover:op-100'" />
  </button>

  <LayoutPopover v-model="historyActive">
    <div lt-lg:landscape="relative w-100vw" lt-md:portrait="relative w-100vw">
      <!-- fix scroll lock with 0.5px -->
      <div class="absolute left-0 hidden h-0.25 w-full bg-gray-3 -bottom-0.25" lt-lg:landscape="block" lt-md:portrait="block" />

      <div
        class="relative z-10 w-115 flex flex-col rd-4 bg-gray-3 p-10"
        lt-lg:landscape="w-full rd-0 pt-6 pb-0 px-5" lt-md:portrait="w-full rd-0 pt-6 pb-0 px-5"
      >
        <p class="text-center text-8 c-#fff c-op-90 font-[OSP-DIN]">
          {{ $t('personal.playedTitle') }}
        </p>

        <template v-if="historyGames.length">
          <CommonScrollList direction="portrait" class="mt-5 md:mt-6" contain-class="max-h-105 lt-lg:landscape:pb-4 lt-md:portrait:pb-4">
            <div class="flex flex-col gap-4">
              <template v-for="(game, i) in historyGames" :key="i">
                <CommonGameHistory :game="game" @click="historyActive = false" />
              </template>
            </div>
          </CommonScrollList>
        </template>

        <template v-else>
          <div class="mb-10 mt-10 flex-center flex-col gap-6" lt-lg:landscape="mb-20" lt-md:portrait="mb-20">
            <img class="size-50" src="@/assets/images/played-empty.png" alt="">
            <p class="text-3.5 c-#fff c-op-50">
              {{ $t('personal.playedEmpty') }}
            </p>
          </div>
        </template>
      </div>
    </div>
  </LayoutPopover>
</template>

<style lang="scss" scoped>
</style>
