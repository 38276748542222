<script setup lang="ts">
import type { ICommonGameBlockProps } from '@/types/components'

const props = defineProps<{
  searchResultData: ICommonGameBlockProps[]
  target: HTMLDivElement | undefined
  isSearchActive: boolean
}>()
let bodyRect: DOMRect | null = null
const positionLeft = ref(0)

onMounted(() => {
  bodyRect = document.body.getBoundingClientRect()
})

whenever(() => props.isSearchActive, () => {
  setTimeout(() => {
    const inputRect = props.target?.getBoundingClientRect()
    if (!inputRect || !bodyRect)
      return
    positionLeft.value = inputRect?.left - bodyRect.left
  }, 300)
})
</script>

<template>
  <div
    class="search-panel fixed z-30 rd-2 bg-gray-3 transition-150 md:max-h-81"
    w="[calc(100vw-2.5rem)] md:81" top="14 xl:17"
    :class="[isSearchActive ? 'op-100' : 'op-0 pointer-events-none', searchResultData.length ? 'p-2' : '']"
    :style="{ 'inset-inline-start': `${positionLeft}px` }"
  >
    <CommonGameSheet v-for="game in searchResultData" :key="game.gameId" icon-class="size-14 md:size-9" :game="game" />
  </div>
</template>
