<script setup lang="ts">
import { createLayoutNavProvide, useLayoutInject } from './context'
import SVG_LOGO from '~/assets/svg/logo.svg'
import SVG_DISCORD_OUTLINE_ICON from '~/assets/svg/discord-outline.svg'

const { needResponsive, fillView } = useLayoutInject()

const { globalContext } = useGlobalContext()
const discordUrl = import.meta.env.VITE_DISCORD_URL

const { isShowPersonalPopup } = usePersonalPopup()

const searchActive = ref(false)
const historyActive = ref(false)
const fixedTop = ref(false)
createLayoutNavProvide({
  searchActive,
  personalActive: isShowPersonalPopup,
  historyActive,
  fixedTop,
})

function scrollChange() {
  fixedTop.value = (window.scrollY || document.documentElement.scrollTop || document.body.scrollTop || 0) > 0
}

onMounted(() => {
  scrollChange()
  onscroll = scrollChange
})
</script>

<template>
  <nav
    class="group fixed z-29 w-full transition-all-300 -translate-y-1px"
    :class="[needResponsive && fillView && 'z-29', fixedTop ? 'z-29' : 'z-4']"
  >
    <div
      class="size-full flex justify-center transition-opacity-30" h="12 lg:17"
      :class="{
        '-mt-9 -lg:mt-11 op-0 lg:group-hover:mt-0 lg:group-hover:op-100 bg-gray-2': needResponsive && fillView,
        'mt-0! op-100! bg-gray-2': fixedTop || searchActive || isShowPersonalPopup || historyActive,
        'op-0 pointer-events-none': globalContext.iosFullScreen,
      }"
    >
      <div class="relative view-content flex items-center" :class="needResponsive && 'filled-content'">
        <NuxtLink to="/" aria-label="home page">
          <SVG_LOGO width="160" height="32" class="translate-y-0.5 transition-all-300" :class="searchActive && 'lt-lg:w-0'" />
        </NuxtLink>

        <section class="flex flex-1 justify-end">
          <LayoutSearchWidget v-model="searchActive" class="nav-search" />

          <LayoutHistoryList class="nav-history" />

          <NuxtLink
            class="ld-button box-border size-9 b-0 transition-all-300 ld-button-black" c="#fff op-50 hover:green-5 hover:op-100" mis="3 lg:6" aria-label="discord" lt-md="hidden"
            :class="[fixedTop && 'bg-gray-3', searchActive && 'lt-lg:w-0 lt-lg:m-0! lt-lg:of-hidden']" :to="discordUrl" target="_blank" external
          >
            <SVG_DISCORD_OUTLINE_ICON />
          </NuxtLink>

          <LayoutPersonal />
        </section>
      </div>
    </div>
  </nav>
  <div class="w-100vw transition-all-300" h="12 lg:17" :class="[needResponsive && fillView && 'h-0!', globalContext.iosFullScreen && 'h-0!']" />
</template>

<style lang="scss" scoped>
</style>
