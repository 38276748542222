<script setup lang="ts">
import { useLayoutNavInject } from './context'
import { ELoginSuccessSource } from '@/types/components'

const { fixedTop, personalActive, searchActive } = useLayoutNavInject()
const { setIsShowPersonalPopup } = usePersonalPopup()
const { isLogged, userInfo, loginSource } = useUserInfo({ needGooglePrompt: true })

const el = ref<HTMLElement>()

function tapShowLoginPanel() {
  setIsShowPersonalPopup(true)
  loginSource.value = ELoginSuccessSource.NAV_ICON
}
</script>

<template>
  <div ref="el" class="size-9" mis="3 lg:6" @click="tapShowLoginPanel">
    <button
      v-if="!isLogged" class="group ld-button size-9 b-2 b-transparent rd-full c-#fff c-op-50 transition-300 ld-button-black hover:c-green-5 hover:c-op-100"
      :class="[fixedTop && 'bg-gray-3', personalActive && 'bg-gray-4 c-green-5! c-op-100!', searchActive && 'lt-lg:w-0 lt-lg:b-none lt-lg:of-hidden']"
      aria-label="login"
    >
      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(#clip0_28_240)" stroke="currentColor" stroke-width="2"><circle cx="8" cy="5" r="4" /><path d="M15 15l-.17-.342A3 3 0 0 0 12.145 13H3.854a3 3 0 0 0-2.683 1.658L1 15" stroke-linecap="round" stroke-linejoin="round" /></g><defs><clipPath id="clip0_28_240"><path fill="#fff" d="M0 0h16v16H0z" /></clipPath></defs></svg>
    </button>
    <ClientOnly v-else>
      <div class="relative size-9 cursor-pointer rd-full transition-300" :class="[fixedTop && 'bg-gray-3', personalActive && 'bg-gray-4', searchActive && 'lt-lg:w-0 lt-lg:of-hidden']">
        <div class="absolute size-full rd-full" b="2 solid #fff op-90" />
        <img :src="userInfo.headPortraitUrl" alt="avatar" class="size-full rd-full">
      </div>
    </ClientOnly>
  </div>

  <LayoutPopover v-model="personalActive">
    <!-- login -->
    <LazyLayoutLogin v-show="!isLogged" />
    <!-- logged -->
    <LazyLayoutUser v-if="isLogged" />
  </LayoutPopover>
</template>

<style lang="scss" scoped>
</style>
